<template>
  <v-text-field
    v-if="visible"
    v-model="value"
    :label="label"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    type="number"
    @blur="handleBlur"
  >
    <template #append-inner>
      <mtf-field-icon
        :meta="meta"
        :has-errors="hasErrors"
      />
    </template>
  </v-text-field>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useMatterificField } from '#imports';
import { isEmpty } from 'lodash-es';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldNumber',
  components: { MtfFieldIcon },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true }
  },
  //emits: ['update:modelValue', 'input',],
  // ----------------

  setup(props, context) {
    const { meta, value, errors, handleBlur, validate, hasErrors } = useMatterificField(
      props.name,
      props.schema,
      context
    );

    // ensure that the value is a number
    // otherwise AJV will complain when validating
    const numericValue = computed({
      get: () => value.value,
      set: (val) => {
        value.value = isEmpty(val) ? val : Number(val);
      }
    });

    return {
      meta,
      value: numericValue,
      errors,
      handleBlur,
      validate,
      hasErrors
    };
  }
});
</script>
